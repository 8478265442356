import dynamic from 'next/dynamic';
import { NextSeo } from 'next-seo';
import { END } from 'redux-saga';
import { wrapper, SagaStore } from 'store';
import { GetStaticProps } from 'next';
import {
  GET_AWARD,
  GET_FOOTER,
  GET_HOME,
  GET_NEWS,
  GET_TRANSLATE,
} from '@containers/Home/slice';

const Home = dynamic(() => import('containers/Home'), {
  ssr: false,
  loading: () => <span>Loading</span>,
});

function PublicContainer() {
  return (
    <>
      <NextSeo title={''} description={''} />
      <Home />
    </>
  );
}
export const getStaticProps: GetStaticProps = wrapper.getStaticProps(
  store => async () => {
    await store.dispatch(GET_HOME());
    await store.dispatch(GET_AWARD());
    await store.dispatch(GET_NEWS({ park: 'SunParadiseLand' }));
    await store.dispatch(GET_FOOTER());
    await store.dispatch(GET_TRANSLATE());
    store.dispatch(END);
    await (store as SagaStore).sagaTask?.toPromise();
    return {
      props: {},
      revalidate: 20,
    };
  },
);
export default PublicContainer;
